<!--
 * @Description: 用户详情
 * @Author: ChenXueLin
 * @Date: 2021-10-18 10:21:15
 * @LastEditTime: 2022-03-31 15:47:35
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="one">
          <user-detail :activeName="activeName"></user-detail>
        </el-tab-pane>
        <el-tab-pane label="用户权限" name="two">
          <user-permission
            :activeName="activeName"
            :treeData="treeData"
          ></user-permission>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import UserDetail from "./userDetail.vue";
import base from "@/mixins/base";
import UserPermission from "./userPermission.vue";
import { getUserRoleTree } from "@/api";
import { printError } from "@/utils/util";
import handleTree from "../../mixins/index";
export default {
  data() {
    return {
      activeName: "one",
      treeData: []
    };
  },
  mixins: [base, handleTree],
  components: { UserDetail, UserPermission },
  created() {
    this.getTreeData();
  },
  methods: {
    //查询角色树
    async getTreeData() {
      try {
        let res = await getUserRoleTree();
        this.handleTreeData(res.data ? [res.data] : []);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-view {
  /deep/.el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 48px);
      overflow-y: auto;
    }
    .el-tab-pane {
      height: 100%;
    }
  }
}</style
>>
